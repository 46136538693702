
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































.logo {
  @include liquid(margin-top, 120px, 170px);

  display: block;
  width: 45%;
  height: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  margin-left: auto;
  fill: currentColor;
}

.read-more {
  @include liquid(font-size, 22px, 28px);

  text-align: center;
}

.header__rich-link {
  @include liquid(margin-bottom, 50px, 100px);

  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.video-player {
  margin-bottom: 4rem;

  @include mq(m) {
    margin-bottom: unset;
  }
}
