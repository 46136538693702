
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































$ar: 16/9;

.video-player-container {
  position: relative;
  width: 100%;
  aspect-ratio: $ar;
  background-color: $c-fluo;

  ::v-deep(.loader) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video-player {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  aspect-ratio: $ar;
}

.player-button {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: $ar;
  overflow: hidden;

  img {
    transition: $transition-duration $ease-out-expo all;
  }

  &:hover,
  &:focus {
    img {
      scale: 1.05;
    }
  }
}

.player-button__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
