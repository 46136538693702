
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































.intro-marquee {
  font-style: italic;
  text-decoration: none;
}

::v-deep {
  .stack.has-multiple-visuals {
    width: min(50rem, 40vw);

    figure:nth-of-type(1) {
      width: 73.6%;
      height: auto;
      transform: translate(-80%, -50%);

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      figcaption {
        width: 75%;
      }
    }

    figure:nth-of-type(3) {
      width: 75.4%;
      height: auto;
      transform: translate(80%, -50%);

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      figcaption {
        right: 0;
        width: 75%;
        text-align: right;
      }
    }
  }

  .has-link {
    .stack {
      margin-bottom: 0;
    }
  }
}

.link-label {
  margin-top: 4rem;
  text-align: center;
}
