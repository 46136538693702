
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































::v-deep {
  .hero__visual {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 70vh;
    max-height: calc(var(--vh, 1vh) * 70);
    object-fit: contain;
  }
}

.hero,
[class*='hero--'] {
  @include liquid('margin-bottom', 18px, 27px);
  @include liquid('margin-top', 150px, 230px);

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px currentColor solid;

  &[class*='--single'] {
    margin-top: var(--header-height);
  }
}

.hero__visual-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 54px 0;
}

.hero__title {
  @include liquid('font-size', 38px, 92px);
  @include liquid('margin-bottom', 30px, 80px);

  width: 100%;
  line-height: 1.2;
  text-align: center;

  &.no-visual {
    @include liquid('margin-top', 70px, 150px);
  }

  &.has-subtitle {
    @include liquid('margin-bottom', 20px, 50px);
  }
}

.hero__subtitle {
  @include liquid('margin-bottom', 45px, 96px);
  @include liquid('font-size', 25px, 50px);

  text-align: center;
}

.infos {
  @include liquid('margin-bottom', 41px, 170px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($from: s) {
    flex-direction: row;
  }
}

.infos__item {
  @include liquid('font-size', 12px, 14px);

  text-transform: uppercase;
}
