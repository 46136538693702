
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































.stack {
  @include liquid(margin-bottom, 100px, 200px);

  &.has-multiple-visuals {
    position: relative;
    z-index: 1; // Prevent children from disapearing behind background
    width: 42%;
    margin-right: auto;
    margin-left: auto;

    ::v-deep {
      .stack__visual {
        object-fit: contain;
        width: 100%;
      }

      figure:nth-of-type(1) {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;
        width: 70%;
        transform: translate(-75%, -50%);

        figcaption {
          width: 70%;
        }
      }

      figure:nth-of-type(3) {
        position: absolute;
        top: 50%;
        right: 0;
        width: 90%;
        transform: translate(75%, -50%);
        align-items: flex-end;

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        figcaption {
          width: 70%;
          text-align: right;
        }
      }

      img,
      picture {
        height: 100%;
      }
    }
  }

  &:not(.has-multiple-visuals) {
    .stack__visual {
      margin: auto;
    }
  }

  ::v-deep {
    img {
      display: block;
    }
  }
}

figure {
  position: relative;
  display: flex;
  flex-direction: column;
}

figcaption {
  @include liquid(font-size, 10px, 14px);
  @include liquid(padding-top, 10px, 16px);

  position: absolute;
  bottom: 0;
  transform: translate(0, 100%);
  line-height: 1.2;
}

.video-player {
  margin-block: 1rem;
}
