
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.o-h {
  overflow: hidden;
}

::v-deep {
  img {
    width: 100%;
    transform: translateY(var(--translate));
  }
}
